<template>
  <div class="empty-seat">
    <div class="empty-img flexdc">
      <img src="../assets/empty.png" />
      <slot><span>请选择查询的班级或学员</span></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "Empty3"
};
</script>
<style lang="less" scoped>
.empty-seat {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -75px;
  margin-top: -100px;
  img {
    width: 10.9375rem;
  }
}
</style>
